import { SelectOption } from "@vahak/core-ui/dist/components/Select/Select.types";
import { LocationSearchParamsOption, searchUsingPlacesAPI } from "@vahak/core/dist/_services/city-search.service";
import { PlaceSearchResult } from "@vahak/core/dist/custom-types/location-search";
import { generateSelectOption } from "./uiUtil";

enum ActionCategoryBasedLocation {
    SEARCH = "SEARCH",
    POSTING = "POSTING",
    COMPANY_UPDATE = "COMPANY_UPDATE"
}

export const ActionCategoryBasedLocationTags: Record<ActionCategoryBasedLocation, LocationSearchParamsOption["tag"]> = {
    [ActionCategoryBasedLocation.SEARCH]: undefined,
    [ActionCategoryBasedLocation.POSTING]: ["point_locations"],
    [ActionCategoryBasedLocation.COMPANY_UPDATE]: ["point_locations"]
};

export const generateCityLoadOption = async (str: string, callback: Function, option: LocationSearchParamsOption) => {
    if (str && str.length >= 2) {
        const results = await searchUsingPlacesAPI(str, option);
        if (results && results.length) {
            const option = results?.map(
                (res: PlaceSearchResult): SelectOption<PlaceSearchResult> =>
                    generateSelectOption(res.description ?? "", res, res.place_id)
            );
            callback(option);
            return;
        }
    }

    callback([]);
};
